import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
import 'normalize.css'
import MobileMenu from '../components/mobile-menu'

export class Layout extends React.Component {


    render() {

        return (
            <div>

                <MobileMenu />

                <MenuWrapper>
                    <DesktopMenu>

                        <a href="/" className="logo">
                            <img src="/images/logo.jpg" alt="Collins Sport Horses Logo" />
                        </a>
                        <ul className="navlinks">
                            <LinkSection><li className="nav-item"><StyledLink to="/">Home</StyledLink></li></LinkSection>
                            <LinkSection><li className="nav-item"><StyledLink to="/services">Services</StyledLink></li></LinkSection>
                            <LinkSection><li className="nav-item"><StyledLink to="/horses-for-sale">Sales</StyledLink></li></LinkSection>
                            <LinkSection><li className="nav-item"><StyledLink to="/about">About</StyledLink></li></LinkSection>
                            <LinkSection><li className="nav-item"><StyledLink to="/contact">Contact</StyledLink></li></LinkSection>
                        </ul>
                        

                    </DesktopMenu>
                </MenuWrapper>
                <div id="page-wrap">
                {this.props.children}
                </div>

                <Footer>
                    <p className="credit">Web Design by <a href="https://denhamrogers.com/" rel="noopener noreferrer" target="_blank">Denham Rogers</a></p>
                </Footer>

            </div>
        );
    }
}


const MenuWrapper = styled.div`
    width: 100%;
    border-bottom: #C1C1C1 solid 1px;
    height: 80px;
`

const DesktopMenu = styled.nav`
    padding: 5px 0;
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    .navlinks {
        @media (max-width: 800px) {
            display: none;
        }
    }
    .nav-item {
        display: flex;
        justiy-content: space-between;
        align-itmes: center;
        font: 'Lora';
        text-transform: uppercase;
        padding: 10px 15px
    }
    .logo {
        display: inline-block;
        img {
            width: 330px;
            vertical-align: middle;
            @media (max-width: 500px) {
                width: 300px;
            }
        }
    }
`;

const StyledLink = styled(props => <Link {...props} />) `
    color: black;
    text-decoration: none;
    position: relative;
    :hover {
        border-bottom: solid 3px #355A2A;
    }
`;

const LinkSection = styled.div`
    cursor: default;
    display: inline-block;
    position: relative;
`;

const Footer = styled.div`
    background-color: black;
    color: white;
    padding: 1em;
    .credit {
        text-align: center;
        a {
            color: white;
        }
    }
`

export default Layout;